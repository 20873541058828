import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "Home",
    icon: "bx-home-circle",
    link: "/molsmart",
  },
  {
    id: 3,
    label: "Meus controles",
    icon: "bx-joystick",
    link: "/controls",
  },
  {
    id: 4,
    label: "Controles públicos",
    icon: "bx-cloud",
    link: "/public-controls",
  },
];
