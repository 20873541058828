// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: "firebase",
  current_api: "https://molsmart-integration.web.app",
  // current_api: "http://localhost:5000",
  firebaseConfig: {
    apiKey: "AIzaSyC5Pk1C6La643BBgNs27MUxZXNYDSrx484",
    authDomain: "sortegram.firebaseapp.com",
    projectId: "sortegram",
    storageBucket: "sortegram.appspot.com",
    messagingSenderId: "714246091947",
    appId: "1:714246091947:web:1aaa28be0cd78e9a9533ee",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
